import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import PATH from './repository/path.json';

import { AuthProvider } from './utils/auth';
import PrivateRoute from './components/PrivateRouter';

import MainTitleBar from './components/MainTitleBar';
import Main from './views/Main';
import UserCreate from './views/UserCreate';
import Login from './views/Login';

import './App.css';

const App = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Switch>
                    <Route exact path={PATH.USER_LOGIN} component={Login} />
                    <Route exact path={PATH.USER_RESIST} component={UserCreate} />
                    <div>
                        <MainTitleBar />
                        <PrivateRoute exact />
                        <PrivateRoute exact path="/main" component={Main} />
                        {/* <PrivateRoute exact path="/sub" component={Main} /> */}
                    </div>
                </Switch>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default App;
