import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import PATH from '../repository/path.json';

import { AuthContext } from '../utils/auth';

const PrivateRouter = ({ component: RouteComponent, ...options }) => {
    console.log('== PrivateRouter-start ==');
    console.log('options : ', options);

    const { currentUser } = useContext(AuthContext);
    console.log('currentUser : ', currentUser);
    const isAuthenticated = currentUser ? true : false;

    console.log('== PrivateRouter-end ==');
    return (
        <Route
            {...options}
            render={(props) =>
                isAuthenticated ? <Route {...options} component={RouteComponent} /> : <Redirect to={PATH.USER_LOGIN} />
            }
        />
    );
};

export default PrivateRouter;
