import React, { createContext, useState, useEffect } from 'react';
import { auth } from './firebase';

import PATH from '../repository/path.json';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    const signup = async (email, password, history) => {
        await auth.createUserWithEmailAndPassword(email, password);
        history.push(PATH.USER_LOGIN);
    };

    const signin = async (email, password, history) => {
        await auth.signInWithEmailAndPassword(email, password);
        history.push('/main');
    };

    const signout = async (history) => {
        await auth.signOut();
        history.push(PATH.USER_LOGIN);
    };

    useEffect(() => {
        auth.onAuthStateChanged((user) => setCurrentUser(user));
    }, []);

    return <AuthContext.Provider value={{ currentUser, signup, signin, signout }}>{children}</AuthContext.Provider>;
};
