import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { AuthContext } from '../utils/auth';

import PATH from '../repository/path.json';

import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    paper: {
        backgroundColor: '#F0F0F0',
        padding: '3em',
    },
    textField: {
        backgroundColor: 'white',
    },
});

const Login = ({ history }) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resMessage, setResMessage] = useState('');

    const { signin } = useContext(AuthContext);

    const submit = async () => {
        if (loading) return;

        setLoading(true);
        try {
            await signin(email, password, history);
        } catch (err) {
            setResMessage(err);
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Container fixed>
                <Paper elevation={0} variant="outlined" className={classes.paper}>
                    <Grid container spacing={3} direction="column" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <TextField
                                id="email"
                                label="メールアドレス"
                                variant="outlined"
                                onChange={(e) => setEmail(e.target.value)}
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                label="パスワード"
                                variant="outlined"
                                onChange={(e) => setPassword(e.target.value)}
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" variant="contained" onClick={submit}>
                                {!loading && 'ログイン'}
                                {loading && <CircularProgress color="white" />}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={() => history.push(PATH.USER_RESIST)}>
                                新規登録はこちら
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                {resMessage && (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {JSON.stringify(resMessage)}
                    </Alert>
                )}
            </Container>
        </React.Fragment>
    );
};

export default withRouter(Login);
