import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import PATH from '../repository/path.json';

import { AuthContext } from '../utils/auth';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';

const MainTitleBar = ({ history }) => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const { signout } = useContext(AuthContext);

    const toggleMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    const clickSignOut = async () => {
        signout(history);
    };

    return (
        <div>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleMenu}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">SampleApp</Typography>
                    <Button color="inherit">Login</Button>
                </Toolbar>
            </AppBar>

            <Drawer open={isOpenMenu} width={200} onClose={toggleMenu}>
                <Link to={PATH.HOME} onClick={toggleMenu}>
                    <MenuItem>トップページ</MenuItem>
                </Link>
                <Link onClick={clickSignOut}>
                    <MenuItem>ログアウト</MenuItem>
                </Link>
            </Drawer>
        </div>
    );
};

export default withRouter(MainTitleBar);
