import React from 'react';
import { withRouter } from 'react-router';

import logo from '../logo.svg';
import '../App.css';

const Main = ({ history }) => {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Editable <code>src/App.js</code> and save to reload.
                </p>
                <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                    Learn React
                </a>
            </header>
        </div>
    );
};

export default withRouter(Main);
